<template>
  <div>
    <a-row v-for="row in rows" :key="row" align="middle"
           class="border-bottom border-primary">
        <span class="font-size-18">{{row}}</span><br>
      <a-col v-for="col in cols" :key="col"
             :xs="{span:6}" :sm="{span:4}"  :md="{span:3}" :lg="{span:3}" :xl="{span:3}" :xxl="{span:2}" >
        <span class="font-size-12">{{col}}</span>
        <gridItem :position="searchPosition(col, row)" :level="level"/>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { fractalMixin } from '@/mixins/fractalMixin'
import gridItem from './gridItem'
export default {
  name: 'wpositions',
  mixins: [fractalMixin],
  components: {
    gridItem,
  },
  props: {
    level: {
      required: false,
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      positions: [],
    }
  },
  computed: {
    cols() {
      const allCols = this.level.wpositions.map(wposition => {
        return wposition.column
      })
      return allCols.filter((item, index) => {
        return allCols.indexOf(item) === index
      })
    },
    rows() {
      const allRows = this.level.wpositions.map(wposition => {
        return wposition.row
      })
      return allRows.filter((item, index) => {
        return allRows.indexOf(item) === index
      })
    },
  },
  methods: {
    searchPosition(col, row) {
      const positions = this.level.wpositions
      let wposition
      positions.forEach(wp => {
        if (wp.column === col && wp.row === row) {
          wposition = wp
        }
      })
      return wposition
    },
  },

}
</script>

<style scoped>

</style>
