<template>
<div>
  <div class="row justify-content-center">
    <div :class="withPagination ? 'd-none' : 'col-sm-12 col-md-1 align-middle'" style="align-self: center">
      <b-button v-if="!currentPageIndex === 0" @click="prev()">
        <b-icon icon="arrow-left" variant="light" />
      </b-button>
    </div>
    <div :class="withPagination ? 'col-12' : 'col-sm-12 col-md-10'">
      <div class="row">
        <div :class="`col-sm-12 col-md-${12/rows*2} col-lg-${12/rows}`" v-for="(item,index) in currentPageW" :key="index" style="min-width: 150px;">
          <b-card no-body >
            <slot :item="item"/>
            <b-card-body>
              <b-button block variant="primary" @click="selectedItem(item)">
                <b-icon icon="hand-index"/>
              </b-button>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
    <div :class="withPagination ? 'd-none' : 'col-sm-12 col-md-1 align-middle'" style="align-self: center">
      <b-button v-if="currentPageIndex < (nbPages - 1)" @click="next()">
        <b-icon icon="arrow-right" variant="light"/>
      </b-button>
    </div>
  </div>
  <div class="row justify-content-end" v-if="withPagination">
    <div class="card-pagination mt-4">
      <b-button :disabled="currentPageIndex === 0" @click="goto(1)">
        <b-icon icon="arrow-left" variant="light" />
      </b-button>
      <b-button :disabled="currentPageIndex === 0" @click="prev()">
        <small>Ant</small>
      </b-button>
      <b-button v-for="i in nbPages" :key="i"
                variant="outline-primary" @click="goto(i)"
                class="page-index" :class={active:currentPage(i)}>
        {{i}}
      </b-button>
      <b-button :disabled="currentPageIndex >= (nbPages - 1)" @click="next()">
        <small>Sig</small>
      </b-button>
      <b-button :disabled="currentPageIndex >= (nbPages - 1)" @click="goto(nbPages)">
        <b-icon icon="arrow-right" variant="light"/>
      </b-button>
    </div>
  </div>
</div>
</template>

<script>
// Este componente no iteractua con la paginacion de la API
export default {
  name: 'cardSlider',
  props: {
    items: {
      default: () => { return [] },
      type: Array,
      required: true,
    },
    rows: {
      default: 4,
      type: Number,
      required: false,
      validator: function (value) {
        // El valor debe coincidir con una de estas cadenas de texto
        return [2, 3, 4, 6].indexOf(value) !== -1
      },
    },
    withPagination: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      paginated: [],
      nbPages: 0,
      currentPageIndex: 0,
    }
  },
  computed: {
    formatted() {
      return this.items.reduce((c, n, i) => {
        if (i % 4 === 0) c.push([])
        c[c.length - 1].push(n)
        return c
      }, [])
    },
    currentPageW() {
      this.createPages()
      return this.items.length === 0 ? [] : this.paginated[this.currentPageIndex]
    },
  },
  methods: {
    currentPage(i) {
      return i - 1 === this.currentPageIndex
    },
    createPages() {
      const lengthAll = Object.keys(this.items).length
      this.nbPages = 0
      for (let i = 0; i < lengthAll; i = i + this.rows) {
        this.paginated[this.nbPages] = this.items.slice(
          i,
          i + this.rows,
        )
        this.nbPages++
      }
    },
    goto(i) {
      this.currentPageIndex = i - 1
    },
    prev() {
      if (this.currentPageIndex > 0) {
        this.currentPageIndex = this.currentPageIndex - 1
      }
    },
    next() {
      if (this.currentPageIndex === 0 || this.currentPageIndex <= (this.nbPages - 1)) {
        this.currentPageIndex = this.currentPageIndex + 1
      }
    },
    selectedItem(item) {
      this.$emit('selected', item)
    },
  },
}
</script>

<style scoped>

</style>
