<template>
  <div>
    <a-tabs style="overflow: auto;" :default-active-key="0" tab-position="left">
      <a-tab-pane v-for="(level, index) in levels" :key="index" >
        <small slot="tab">{{ level.name }}</small>
        <h6>Posiciones de {{level.name}}</h6>
        <hr class="bg-primary">
        <wpositions v-if="level" :level="level"/>
      </a-tab-pane>
    </a-tabs>
    <p class="text-center mt-4">
      Seleccione la posición,
      <span class="text-warning">
        en caso de estar ocupada o bloqueada presione click derecho para cambiar el estatus.
      </span>
    </p>
  </div>
</template>

<script>
import { fractalMixin } from '@/mixins/fractalMixin'
import wpositions from './wpositions'
export default {
  name: 'warehouseGrid',
  mixins: [fractalMixin],
  components: {
    wpositions,
  },
  props: {
    levels: {
      required: true,
      type: Array,
    },
  },
}
</script>

<style scoped>

</style>
