<template>
  <div>
    <a-drawer title="Detalle de Posición"
              width="640"
      placement="right"
      :closable="false"
      :visible="visibleDrawer"
      :after-visible-change="afterVisibleChange"
      @close="onClose"
    >
      <div class="row bg-gray-1 px-2 py-4">
        <!--FORMULARIO-->
        <a-form-model :model="auxForm" :rules="rules" ref="wpositionForm"
            layout="vertical" :wrapper-col="{ span: 24}">
          <a-row align="middle" justify="center">
            <a-col span="6">
              <!--TEXT - Columna-->
              <a-form-model-item label="Columna" ref="column"  prop="column" >
                <a-input v-model.trim="auxForm.form.column" placeholder="Columna" size="large" disabled/>
              </a-form-model-item>
              <!--TEXT -Columna -->
            </a-col>
            <a-col span="6">
              <!--TEXT - Fila-->
              <a-form-model-item label="Fila" ref="row"  prop="row" >
                <a-input v-model.trim="auxForm.form.row" placeholder="Fila" size="large" disabled/>
              </a-form-model-item>
              <!--TEXT -Fila -->
            </a-col>
            <a-col span="12">
              <!--TEXT - Nombre-->
              <a-form-model-item label="Nombre" ref="name"  prop="name" >
                <a-input v-model.trim="auxForm.form.name" placeholder="Nombre" size="large" disabled/>
              </a-form-model-item>
              <!--TEXT -Nombre -->
            </a-col>
          </a-row>
          <a-row align="middle" justify="center">
            <a-col span="12">
              <!-- Estatus -->
              <a-form-model-item label="Estatus" ref="status"  prop="status" >
                <a-radio-group v-model="auxForm.form.status" button-style="solid">
                  <a-radio-button value="Libre">Libre</a-radio-button>
                  <a-radio-button value="Ocupado">Ocupado</a-radio-button>
                  <a-radio-button value="Bloqueado">Bloqueado</a-radio-button>
                </a-radio-group>
              </a-form-model-item>
              <!-- Estatus -->
            </a-col>
          </a-row>
          <a-row align="middle" justify="end" type="flex" v-if="withControls">
            <b-button  variant="primary" @click="Submit" pill>
                <div class="d-none d-md-block" v-b-tooltip.hover title="Actualizar">
                    <b-icon icon="archive"/> Actualizar
                </div>
                <div class="d-md-none" v-b-tooltip.hover title="Actualizar">
                    <b-icon icon="archive"/>
                </div>
            </b-button>
          </a-row>

        </a-form-model>
        <!--FORMULARIO-->
      </div>
      <hr class="bg-primary">
      <h4 class="bg-primary rounded text-center text-white py-1 mb-4">Elementos almacenados</h4>
      <hr class="bg-primary">
      <div class="row justify-content-center bg-gray-1 px-2 py-4 mt-3">
        <titemsResume :items="detailWpositionDrawer.titems"/>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import titemsResume from './titemsResume'
import { fractalMixin } from '@/mixins/fractalMixin'
export default {
  name: 'wpositionDrawer',
  mixins: [fractalMixin],
  props: {
    withControls: {
      required: false,
      default: true,
      type: Boolean,
    },
  },
  components: {
    titemsResume,
  },
  computed: {
    ...mapState('warehouse', ['wpositionForm', 'visibleDrawer', 'detailWpositionDrawer']),
    auxForm: {
      get() {
        return this.wpositionForm
      },
      set(value) {
        this.UPDATE_WPFORM(value)
      },
    },
  },
  data() {
    return {
      rules: {
        // wlevels: [{ required: true, message: 'El campo Nivel es obligatorio.', trigger: 'blur' }],
        // column: [{ required: true, message: 'El campo Columna es obligatorio.', trigger: 'blur' }],
        // row: [{ required: true, message: 'El campo Fila es obligatorio.', trigger: 'blur' }],
        // name: [{ required: true, message: 'El campo Nombre es obligatorio.', trigger: 'blur' }],
        // status: [{ required: true, message: 'El campo Estatus es obligatorio.', trigger: 'blur' }],
      },
      status: ['Libre', 'Ocupado', 'Bloqueado'],
    }
  },
  methods: {
    ...mapActions('warehouse', ['TOOGLE_DRAWER', 'UPDATE_WPFORM', 'RESET_WPFORM']),
    afterVisibleChange(val) {
      if (val) {
        this.BuildForm()
      } else this.RESET_WPFORM()
    },
    showDrawer() {
      this.TOOGLE_DRAWER(true)
    },
    onClose() {
      this.TOOGLE_DRAWER(false)
    },
    Submit() {
      this.$refs.wpositionForm.validate(async(valid) => {
        if (valid) {
          this.spinnerLoad = true
          this.PutResource(this.auxForm, () => {
            this.$emit('updated')
            this.onClose()
          }, (error) => console.log(error))
        } else return false
      })
    },
    BuildForm() {
      const form = {
        id: this.detailWpositionDrawer.id,
        wlevels: this.detailWpositionDrawer.wlevel_id.toString(),
        column: this.detailWpositionDrawer.column,
        row: this.detailWpositionDrawer.row,
        name: this.detailWpositionDrawer.name,
        status: this.detailWpositionDrawer.status,
      }
      this.UPDATE_WPFORM(form)
    },
  },
}
</script>

<style scoped>

</style>
