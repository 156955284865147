<template>
  <div :class="bgClass">
    <a  class="text-center" :id="`popover-${position.name}`" @click.right="rightClick($event)">
      <b-img :src="img" fluid :alt="position.name"
             v-if="position.status === 'Libre' || position.status === 'Ocupado'" @click="Selected(position)"/>
      <b-img :src="img" fluid :alt="position.name" v-else/>
    </a>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'gridItem',
  props: {
    position: {
      required: true,
      type: Object,
    },
    level: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapState('warehouse', ['selectedPosition', 'visibleDrawer']),
    bgClass () {
      if (this.selectedPosition === this.position.id) {
        return 'bg-real-blue mr-1 p-1'
      } else return 'bg-gray-3 mr-1 p-1'
    },
    img() {
      if (this.position.status === 'Libre') {
        return '/resources/images/btns/openBox.png'
      } else if (this.position.status === 'Ocupado') {
        return '/resources/images/btns/closedBox.png'
      } else return '/resources/images/btns/lockedBox.png'
    },
  },
  methods: {
    ...mapActions('warehouse', ['SELECT_WPOSITION', 'TOOGLE_DRAWER', 'STORE_DETAILWPOSITIONDRAWER']),
    Selected(position) {
      this.SELECT_WPOSITION(position.id)
    },
    rightClick(e) {
      e.preventDefault()
      this.TOOGLE_DRAWER(true)
      console.log(this.position)
      this.STORE_DETAILWPOSITIONDRAWER(this.position)
    },
  },

}
</script>

<style scoped>

</style>
