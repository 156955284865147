import { render, staticRenderFns } from "./wpositions.vue?vue&type=template&id=375f8ed4&scoped=true&"
import script from "./wpositions.vue?vue&type=script&lang=js&"
export * from "./wpositions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "375f8ed4",
  null
  
)

export default component.exports